//External imports
import { useEffect, useRef } from 'react';

const loadScript = (src) =>
  new Promise((resolve, reject) => {
    if (document.querySelector(`script[src="${src}"]`)) return resolve();
    const script = document.createElement('script');
    script.src = src;
    script.onload = () => resolve();
    script.onerror = (err) => reject(err);
    document.body.appendChild(script);
  });

const GoogleAuth = ({ handleCredentialResponse, styles,config }) => {

  const googleButton = useRef(null);


  useEffect(() => {
    const src = 'https://accounts.google.com/gsi/client';
    const id = process.env.googleAppId;

    loadScript(src).then(() => {
      /*global google*/
      google.accounts.id.initialize({
        client_id: id,
        callback: handleCredentialResponse,
        hl: 'en'
      });
      google.accounts.id.renderButton(
        googleButton.current,
        config || {
          theme: 'outline',
          size: 'medium',
          type: 'icon',
          shape: 'circle',
          text: 'continue_with',
          locale: 'en_US',
        },
      );
    }).catch(console.error);

    return () => {
      const scriptTag = document.querySelector(`script[src="${src}"]`);
      if (scriptTag) document.body.removeChild(scriptTag);
    };
  }, []);

  return (
    <div ref={googleButton} className={styles}></div>
  );
};

export default GoogleAuth;
